import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    RootState, useAppDispatch, useAppSelector,
    loadChecklists, updateChecklist, deleteChecklist,
    updateChecklistItem, deleteChecklistItem
 } from '../../redux';
import { Checklist, ChecklistItem } from '../../models';
import { Progress } from 'flowbite-react';
import { AutoSizeTextarea, Confirm, Icon, Tooltip } from '..';

import _ from 'lodash';

interface ChecklistsProps {
    change ?: string;
    project?: string;
    user   ?: string;
}

function Checklists(props: ChecklistsProps) {
    const { t } = useTranslation();

    const { change, project, user } = props;
    const dispatch                  = useAppDispatch();

    let { checklists } = useAppSelector((state: RootState) => state.checklists);
    if (change)
        checklists = checklists.filter((c: Checklist) => c.change?._id === change);

    const [idOfListToDelete, setIdOfListToDelete] = React.useState('');

    useEffect(() => {
        dispatch(loadChecklists({ change, project, user }));
    }, [dispatch, change, project, user]);

    const saveListName = (listId: string, value: any) => {
        const params = {
            _id:  listId,
            name: value
        };
       dispatch(updateChecklist(params, /*patch*/true));
    }

    const deleteList = () => {
        dispatch(deleteChecklist(idOfListToDelete, (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
            dispatch(loadChecklists({ project, change, user }));
        }));
        setIdOfListToDelete('');
    }

    const addItemInList = (listId: string, value: any) => {
        onSaveItem(listId, /*itemId*/ "", /*property*/ "name", /*defaultValue*/ null, value);
    }

    const onSaveItem = async (listId: string, itemId: string, property: string, defaultValue: any, value: any) => {
        if (defaultValue === value)
            return;

        if (!itemId) {
            const list = checklists.find((list: Checklist) => list._id === listId);
            list.items = list.items.filter((item: ChecklistItem) => !!item.name);
            list.items.push({
                [property]: value,
                assigned: false,
                done: false
            });
            await dispatch(updateChecklist(list, /*patch*/ true));
            return dispatch(loadChecklists({ project, change, user }));
        }

        dispatch(updateChecklistItem(listId, { _id: itemId, [property]: value }));
    }

    const handleChange = (listId: string, itemId: string, checked: boolean) => {
        return onSaveItem(listId, itemId, 'done', !checked, checked);
    }

    const deleteItem = (listId: string, itemId: string) => {
        dispatch(deleteChecklistItem(listId, itemId));
    }

    const listProgression = (list: Checklist) => {
        const progression = Math.round(list?.progression());

        return (
            <Tooltip content={progression + '%'} placement="top">
                <div>
                    <Progress
                        progress={progression}
                        size="sm"
                        color={ progression === 100 ? 'green' : (progression >= 50 ? 'cyan' : 'yellow')}
                    />
                </div>
            </Tooltip>
        );
    };

    if (!checklists)
        return null;

    return (
        <div className='checklists flex-col space-y-4'>
        {checklists.map((c: Checklist) => (
            <div id={"checklist-" + c._id} key={c._id} className="pb-3 border-b">
                <div className="flex flex-col space-y-0 bg-blue50 pb-1">
                    <div className="text-sm cursor-pointer flex space-x-3 group items-center rounded-t">
                        <Icon type="list" className="ml-1.5" size={5} />
                        <input
                            key={c.name}
                            type="text"
                            className="border-0 font-bold w-full px-2 py-0 focus:ring-0"
                            defaultValue={c.name}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const target = e.target as HTMLInputElement;
                                    saveListName(c._id, target.value);
                                }
                            }}
                        />
                        <div className='flex items-center p-1.5 text-xs text-gray-500 font-normal hover:underline'>
                            <Icon type="delete" size={3} className="mr-0.5" />
                            <span onClick={() => { setIdOfListToDelete(c._id); }}>
                                {t('common.delete')}
                            </span>
                        </div>
                    </div>
                    <div className="flex justify-between items-center space-x-0 px-1.5">
                        <span className="text-xs w-[33px]">
                            { Math.round(c.progression()) }%
                        </span>
                        <div className="flex-1">{ listProgression(c) }</div>
                    </div>
                </div>

                <div className="py-2 flex flex-col space-y-0">
                    {c.items && c.items.map((item: ChecklistItem, index) => (
                        <div key={index} className="px-1.5 flex items-center justify-between hover:bg-gray-50">
                            <div className="flex w-full" key={item._id}>
                            {item._id && (
                                <input
                                    type="checkbox"
                                    className="mt-1 mr-1 "
                                    name="item.done"
                                    checked={item.done}
                                    onChange={ (e) => handleChange(c._id, item._id, !!e.target.checked)}
                                />
                            )}
                                <AutoSizeTextarea
                                    key={item.name}
                                    defaultValue={item.name}
                                    className={`text-sm pl-2 border-0 focus:ring-0 p-0 py-0.5 w-full cursor-pointer ${item.done ? 'line-through' : ''}`}
                                    onKeyDown={(e: AnyObj) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (e.target.value === '')
                                                deleteItem(c._id, item._id);
                                            else
                                                onSaveItem(c._id, item._id, 'name', item.name, e.target.value);
                                        }
                                    }}
                                />
                            </div>
                            {item._id && (
                                <div className='hidden group-hover:flex'>
                                    <span><Icon type="assigned" className='mr-1 hidden'></Icon> </span>
                                    <span onClick={() => { deleteItem(c._id, item._id) }}><Icon type="delete" className='text-red-600 cursor-pointer'></Icon> </span>
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="px-1.5">
                        <input
                            type="text"
                            placeholder= {t('changes.checklist.add_an_item') || ''}
                            className="text-sm p-0 mt-3 bg-gray-100 border-gray-200 rounded px-2 py-1 w-full focus:ring-0"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const target = e.target as HTMLInputElement;
                                    addItemInList(c._id, target.value);
                                    target.value = '';
                                }
                            }}
                        />
                    </div>
                </div>
                <Confirm
                    visible={idOfListToDelete.length > 0}
                    onConfirm={() => deleteList()}
                    onCancel={() => setIdOfListToDelete('')}
                    title={t('changes.checklist.are_you_sure_to_delete') || ''}
                />
            </div>
        ))}
        </div>
    );
}

export default Checklists;
