import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleField } from '../../../components';
import { Field } from 'formik';

interface ClientAreaFormProps {};

function ClientAreaForm(props: ClientAreaFormProps) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 space-x-0 lg:space-x-12">
            <div className="flex-1">
                <h2 className="mt-4 mb-1 text-base font-semibold leadin text-lg text-gray-900">
                    {t('settings.client_area')}
                </h2>
                <div className="mt-5">
                    <label htmlFor="api_url" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.client_area_api_url')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="text"
                            name="client_area.api_url"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://tickets.net/showTicket?id=%d"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="api_key" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.client_area_api_key')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="text"
                            name="client_area.api_key"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://tickets.net/showTicket?id=%d"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientAreaForm;
