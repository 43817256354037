import _ from 'lodash';
import BaseModel from './BaseModel';
import moment from 'moment';
import type { Moment } from 'moment';

/** Class representing a setting. */
class Setting extends BaseModel {
    _id: string;
    auth: {
        password: {
            enabled: boolean;
        },
        openid: {
            enabled: boolean;
            configuration_url: string;
            client_id: string;
            client_secret: string;
        }
    };
    openai: {
        api_key: string;
        model: string;
    };
    apprise: {
        signal: {
            enabled: boolean;
            host: string;
            port: number;
            username: string;
        },
        email: {
            enabled: boolean;
            host: string;
            port: number;
            secure: boolean;
            auth: {
                user: string;
                pass: string;
            }
            from: string;
        }
    };
    ticketing: {
        enabled: boolean;
        ticket_url: string;
        reply_url: string;
        close_url: string;
    };
    documentations: {
        local: string;
        outline: {
            api_key: string;
            url: string;
            rootDocumentId: string;
        }
    };
    client_area: {
        api_url: string;
        api_key: string;
    };
    reminders: {
        frequency: string;
    };
    createdAt: Moment;
    updatedAt: Moment;

    constructor(properties: AnyObj) {
        super({});

        this._id            = properties._id;
        this.auth           = properties.auth;
        this.openai         = properties.openai;
        this.apprise        = properties.apprise;
        this.ticketing      = properties.ticketing;
        this.documentations = properties.documentations;
        this.client_area    = properties.client_area;
        this.reminders      = properties.reminders;
        this.createdAt      = moment(properties.createdAt);
        this.updatedAt      = moment(properties.updatedAt);
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() : AnyObj {
        const prepared = _.cloneDeep(this) as AnyObj;

        prepared.auth.openid.enabled    = !!prepared.auth.openid.enabled;
        prepared.auth.password.enabled  = !!prepared.auth.password.enabled;
        prepared.apprise.signal.enabled = !!prepared.apprise.signal.enabled;
        prepared.apprise.email.enabled  = !!prepared.apprise.email.enabled;
        prepared.apprise.email.secure   = !!prepared.apprise.email.secure;
        prepared.ticketing.enabled      = !!prepared.ticketing.enabled;

        return prepared;
    }
}

export default Setting;
