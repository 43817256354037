import { Settings } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';

const {
    initialState,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    duplicateReducer,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
    duplicateAction,
} = createRestSlices(Settings);

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        duplicateReducer
    ]);
}

/* Export CRUD actions */
export const createSetting        = createAction;
export const startCreateSetting   = startCreateAction;
export const loadSetting          = getAction;
export const loadSettings         = listAction;
export const updateSetting        = updateAction;
export const deleteSetting        = deleteAction;
export const duplicateSetting     = duplicateAction;
