import React from 'react';
import {
    VscHome, VscAccount,
    VscAdd, VscArrowCircleLeft, VscClose, VscLock,
    VscSave, VscEdit, VscCopy, VscTrash, VscCalendar, VscLink,
    VscCheck, VscWarning, VscInfo, VscSync,
    VscLoading, VscHistory, VscLaw, VscSymbolInterface, VscFileBinary,
    VscRepoForked, VscRocket, VscGitCommit, VscPulse, VscMilestone, VscOutput,
    VscBug, VscCode, VscBracketError, VscComment, VscNote, VscSymbolColor, VscSettings,
    VscDebugAll, VscPackage, VscGlobe, VscBeaker, VscGitMerge, VscSearchFuzzy,
    VscChevronUp, VscChevronRight, VscChevronDown, VscChevronLeft, VscChecklist, VscMenu, VscDebugLineByLine,
    VscThumbsup, VscSearch, VscStarEmpty, VscStarFull, VscNewFile, VscWatch, VscPerson, VscProject,
    VscTag, VscSquirrel, VscCreditCard, VscEye, VscCloudDownload, VscPieChart, VscTasklist, VscBell,
    VscMail, VscReply, VscArrowSwap, VscSymbolEvent, VscEllipsis, VscGear
} from 'react-icons/vsc';
import {
    RiAttachment2, RiLogoutCircleLine, RiTimer2Line,
    RiTimerLine, RiStopCircleLine, RiPlayCircleLine,
    RiMapPinTimeLine, RiEmotionSadLine, RiTableAltLine,
    RiFlowChart
} from 'react-icons/ri';
import { PiArrowElbowDownRightThin, PiMagicWandThin, PiColumnsLight, PiStepsDuotone, PiChatCenteredTextThin, PiTicketThin } from "react-icons/pi";
import { GiStairsGoal } from "react-icons/gi";
import { SiOpenid, SiSignal } from "react-icons/si";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { FaRankingStar, FaUserTie } from "react-icons/fa6";
import { TbUrgent, TbArrowMoveRight } from "react-icons/tb";


type IconType =
    'home' | 'user' | 'plus' | 'back' | 'close' | 'lock' | 'save' | 'edit' | 'copy' | 'delete' | 'date' |
    'view' | 'check' | 'warning' | 'info' | 'sync' | 'loading' | 'running' | 'from' | 'law' | 'progress' | 'quote' |
    'project' | 'branch' | 'release' | 'commit' | 'activity' | 'sprint' | 'changelog' |
    'bug' | 'code' | 'lint' | 'comment' | 'notes' | 'ui' | 'logout' | 'settings' |
    'test' | 'build' | 'globe' | 'feature' | 'merge' | 'chore' |
    'up' | 'right' | 'down' | 'left' | 'rightdown' | 'list' | 'menu' | 'todo' |
    'thumbs-up' | 'search' | 'emptyStar' | 'fullStar' | 'created' | 'patch' |
    'estimated' | 'unestimated' | 'planned' | 'unplanned' | 'assigned' | 'unassigned' | 'title' |
    'advance' | 'bill'| 'file' | 'eye' | 'download' | 'timer' | 'stop' | 'start' |
    'sad' | 'chart' | 'task' | 'reminder' | 'magic' | 'board' | 'column' | 'progression' | 'sso' | 'email' |
    'priority' | 'importance' | 'reply' | 'description' | 'ticket' | 'signal' | 'routine' |
    'swap' | 'event' | 'move' | 'client' | 'report' | 'goal' | 'dots' | 'setting';
export type { IconType };

interface IconProps {
    type: IconType,
    color?: string,
    size?: number,
    className?: string
}

function Icon(props: IconProps) {
    const { type, color, size, className } = props;

    const classNames = `h-${size || 4} w-${size || 4} flex-shrink-0 text-${color || 'gray-900'} ${className || ''}`;

    switch (type) {
        case 'home':
            return <VscHome className={classNames} aria-hidden="true" />;
        case 'user':
            return <VscAccount className={classNames} aria-hidden="true" />;
        case 'back':
            return <VscArrowCircleLeft className={classNames} aria-hidden="true" />;
        case 'close':
            return <VscClose className={classNames} aria-hidden="true" />;
        case 'lock':
            return <VscLock className={classNames} aria-hidden="true" />;
        case 'plus':
            return <VscAdd className={classNames} aria-hidden="true" />;
        case 'save':
            return <VscSave className={classNames} aria-hidden="true" />;
        case 'edit':
            return <VscEdit className={classNames} aria-hidden="true" />;
        case 'copy':
            return <VscCopy className={classNames} aria-hidden="true" />;
        case 'delete':
            return <VscTrash className={classNames} aria-hidden="true" />;
        case 'date':
            return <VscCalendar className={classNames} aria-hidden="true" />;
        case 'view':
            return <VscLink className={classNames} aria-hidden="true" />;
        case 'check':
            return <VscCheck className={classNames} aria-hidden="true" />;
        case 'warning':
            return <VscWarning className={classNames} aria-hidden="true" />;
        case 'info':
            return <VscInfo className={classNames} aria-hidden="true" />;
        case 'sync':
            return <VscSync className={classNames} aria-hidden="true" />;
        case 'loading':
            return <VscLoading className={'animate-spin ' + classNames} aria-hidden="true" />;
        case 'running':
            return <RiTimer2Line className={'animate-spin ' + classNames} aria-hidden="true" />;
        case 'from':
            return <RiMapPinTimeLine className={classNames} aria-hidden="true" />;
        case 'law':
            return <VscLaw className={classNames} aria-hidden="true" />;
        case 'progress':
            return <VscSymbolInterface className={classNames} aria-hidden="true" />;
        case 'quote':
            return <VscFileBinary className={classNames} aria-hidden="true" />;
        case 'project':
            return <VscProject className={classNames} aria-hidden="true" />;
        case 'branch':
            return <VscRepoForked className={classNames} aria-hidden="true" />;
        case 'release':
            return <VscRocket className={classNames} aria-hidden="true" />;
        case 'commit':
            return <VscGitCommit className={classNames} aria-hidden="true" />;
        case 'activity':
            return <VscPulse className={classNames} aria-hidden="true" />;
        case 'sprint':
            return <VscMilestone className={classNames} aria-hidden="true" />;
        case 'changelog':
            return <VscOutput className={classNames} aria-hidden="true" />;
        case 'bug':
            return <VscBug className={classNames} aria-hidden="true" />;
        case 'code':
            return <VscCode className={classNames} aria-hidden="true" />;
        case 'lint':
            return <VscBracketError className={classNames} aria-hidden="true" />;
        case 'comment':
            return <VscComment className={classNames} aria-hidden="true" />;
        case 'notes':
            return <VscNote className={classNames} aria-hidden="true" />;
        case 'ui':
            return <VscSymbolColor className={classNames} aria-hidden="true" />;
        case 'logout':
            return <RiLogoutCircleLine className={classNames} aria-hidden="true" />;
        case 'settings':
            return <VscSettings className={classNames} aria-hidden="true" />;
        case 'test':
            return <VscDebugAll className={classNames} aria-hidden="true" />;
        case 'build':
            return <VscPackage className={classNames} aria-hidden="true" />;
        case 'globe':
            return <VscGlobe className={classNames} aria-hidden="true" />;
        case 'feature':
            return <VscBeaker className={classNames} aria-hidden="true" />;
        case 'merge':
            return <VscGitMerge className={classNames} aria-hidden="true" />;
        case 'chore':
            return <VscSearchFuzzy className={classNames} aria-hidden="true" />;
        case 'up':
            return <VscChevronUp className={classNames} aria-hidden="true" />;
        case 'right':
            return <VscChevronRight className={classNames} aria-hidden="true" />;
        case 'down':
            return <VscChevronDown className={classNames} aria-hidden="true" />;
        case 'left':
            return <VscChevronLeft className={classNames} aria-hidden="true" />;
        case 'rightdown':
            return <PiArrowElbowDownRightThin className={classNames} aria-hidden="true" />;
        case 'list':
            return <VscChecklist className={classNames} aria-hidden="true" />;
        case 'menu':
            return <VscMenu className={classNames} aria-hidden="true" />;
        case 'todo':
            return <VscDebugLineByLine className={classNames} aria-hidden="true" />;
        case 'thumbs-up':
            return <VscThumbsup className={classNames} aria-hidden="true" />;
        case 'search':
            return <VscSearch className={classNames} aria-hidden="true" />;
        case 'emptyStar':
            return <VscStarEmpty className={classNames} aria-hidden="true" />;
        case 'fullStar':
            return <VscStarFull className={classNames} aria-hidden="true" />;
        case 'created':
            return <VscNewFile className={classNames} aria-hidden="true" />;
        case 'patch':
            return <VscEdit className={classNames} aria-hidden="true" />;
        case 'estimated':
            return <VscWatch className={classNames} aria-hidden="true" />;
        case 'unestimated':
            return <VscWatch className={classNames} aria-hidden="true" />;
        case 'planned':
            return <VscProject className={classNames} aria-hidden="true" />;
        case 'unplanned':
            return <VscProject className={classNames} aria-hidden="true" />;
        case 'assigned':
            return <VscPerson className={classNames} aria-hidden="true" />;
        case 'unassigned':
            return <VscPerson className={classNames} aria-hidden="true" />;
        case 'title':
            return <VscTag className={classNames} aria-hidden="true" />;
        case 'advance':
            return <VscSquirrel className={classNames} aria-hidden="true" />;
        case 'bill':
            return <VscCreditCard className={classNames} aria-hidden="true" />;
        case 'file':
            return <RiAttachment2 className={classNames} aria-hidden="true" />;
        case 'eye':
            return <VscEye className={classNames} aria-hidden="true" />;
        case 'download':
            return <VscCloudDownload className={classNames} aria-hidden="true" />;
        case 'timer':
            return <RiTimerLine className={classNames} aria-hidden="true" />;
        case 'stop':
            return <RiStopCircleLine className={classNames} aria-hidden="true" />;
        case 'start':
            return <RiPlayCircleLine className={classNames} aria-hidden="true" />;
        case 'sad':
            return <RiEmotionSadLine className={classNames} aria-hidden="true" />;
        case 'chart':
            return <VscPieChart className={classNames} aria-hidden="true" />;
        case 'task':
            return <VscTasklist className={classNames} aria-hidden="true" />;
        case 'reminder':
            return <VscBell className={classNames} aria-hidden="true" />;
        case 'magic':
            return <PiMagicWandThin className={classNames} aria-hidden="true" />;
        case 'board':
            return <RiTableAltLine className={classNames} aria-hidden="true" />;
        case 'column':
            return <PiColumnsLight className={classNames} aria-hidden="true" />;
        case 'progression':
            return <PiStepsDuotone className={classNames} aria-hidden="true" />;
        case 'sso':
            return <SiOpenid className={classNames} aria-hidden="true" />;
        case 'email':
            return <VscMail className={classNames} aria-hidden="true" />;
        case 'priority':
            return <TbUrgent className={classNames} aria-hidden="true" />;
        case 'importance':
            return <FaRankingStar className={classNames} aria-hidden="true" />;
        case 'reply':
            return <VscReply className={classNames} aria-hidden="true" />;
        case 'description':
            return <PiChatCenteredTextThin className={classNames} aria-hidden="true" />;
        case 'ticket':
            return <PiTicketThin className={classNames} aria-hidden="true" />;
        case 'signal':
            return <SiSignal className={classNames} aria-hidden="true" />;
        case 'routine':
            return <RiFlowChart className={classNames} aria-hidden="true" />;
        case 'swap':
            return <VscArrowSwap className={classNames} aria-hidden="true" />;
        case 'event':
            return <VscSymbolEvent className={classNames} aria-hidden="true" />;
        case 'move':
            return <TbArrowMoveRight className={classNames} aria-hidden="true" />;
        case 'client':
            return <FaUserTie className={classNames} aria-hidden="true" />;
        case 'report':
            return <HiOutlineDocumentReport className={classNames} aria-hidden="true" />;
        case 'goal':
            return <GiStairsGoal className={classNames} aria-hidden="true" />;
        case 'dots':
            return <VscEllipsis className={classNames} aria-hidden="true" />;
        case 'setting':
            return <VscGear className={classNames} aria-hidden="true" />;
        default:
            return <VscCheck className={classNames} aria-hidden="true" />;
    }
}

export default Icon;
