import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleField } from '../../../components';
import { Field } from 'formik';

interface AppriseFormProps {};

function AppriseForm(props: AppriseFormProps) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 space-x-0 lg:space-x-12">
            <div className="flex-1">
                <div className="mt-5">
                    <h2 className="mt-4 mb-1 text-base font-semibold leadin text-lg text-gray-900">
                        {t('settings.apprise_signal')}
                    </h2>
                    <div className="mt-2 flex space-between">
                        <label htmlFor="signal" className="flex-1 text-sm font-medium leading-6 text-gray-900">
                            {t('settings.enable_apprise_signal')}
                        </label>
                        <ToggleField name="apprise.signal.enabled" className="inline"/>
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="host" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.apprise_signal_host')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="text"
                            name="apprise.signal.host"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://sso.net"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="port" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.apprise_signal_port')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="number"
                            name="apprise.signal.port"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://sso.net"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.apprise_signal_username')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="text"
                            name="apprise.signal.username"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://sso.net"
                        />
                    </div>
                </div>
            </div>
            <div className="flex-1">
                <div className="mt-5">
                    <h2 className="mt-4 mb-1 text-base font-semibold leadin text-lg text-gray-900">
                        {t('settings.apprise_email')}
                    </h2>
                    <div className="mt-2 flex space-between">
                        <label htmlFor="email" className="flex-1 text-sm font-medium leading-6 text-gray-900">
                            {t('settings.enable_apprise_email')}
                        </label>
                        <ToggleField name="apprise.email.enabled" className="inline"/>
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="host" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.apprise_email_host')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="text"
                            name="apprise.email.host"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://sso.net"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="port" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.apprise_email_port')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="number"
                            name="apprise.email.port"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://sso.net"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="email" className="flex-1 text-sm font-medium leading-6 text-gray-900">
                        {t('settings.enable_apprise_email_secure')}
                    </label>
                    <div className="mt-2 flex space-between">
                        <ToggleField name="apprise.email.secure" className="inline"/>
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="user" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.apprise_email_user')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="text"
                            name="apprise.email.auth.user"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://sso.net"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="pass" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.apprise_email_pass')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="password"
                            name="apprise.email.auth.pass"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://sso.net"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppriseForm;
