import RestClient from './RestClient';
import { Setting } from '../models';

class Settings extends RestClient {
    constructor() {
        super({
            // rest config
            model: Setting,
            entryPoint: 'settings',
            sortBy: 'startAt',
            id_field: '_id',
            // redux config
            resource: 'setting',
            resources: 'settings',
        });
    }
}

const SettingsApi = new Settings();

export default SettingsApi;
