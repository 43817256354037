import React, { useEffect } from 'react';
import { SelectField, MultiSelectField } from '../';
import { User } from '../../models';
import { RootState, useAppSelector, useAppDispatch, loadUsers } from '../../redux';

interface UserSelectFieldProps {
    name: string;
    multiple?: boolean;
    placeholder?: string;
    className?: string;
    roles?: string[];
    filter?: (user: User) => boolean;
};

const UserSelectField = (props: UserSelectFieldProps) => {
    const { name, multiple, placeholder, className, roles, filter } = props;

    const dispatch  = useAppDispatch();

    let users = useAppSelector((state: RootState) => state.users.users);

    if (roles && roles.length > 0)
        users = users.filter((user: User) => roles?.includes(user.role));

    if (filter)
        users = users.filter(filter);

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    const options = users?.map((user: User) => ({
        value: user._id,
        label: user.fullname()
    })).sort((a: AnyObj, b: AnyObj) => a.label > b.label ? 1 : -1);

    if (multiple)
        return (
            <MultiSelectField
                name={name}
                options={options}
                placeholder={placeholder}
                className={className}
            />
        );

    return (
        <SelectField
            name={name}
            options={options}
            placeholder={placeholder}
            className={className}
        />
    );
};

export default UserSelectField;
