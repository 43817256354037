import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleField } from '../../../components';
import { Field } from 'formik';

interface TicketingFormProps {};

function TicketingForm(props: TicketingFormProps) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 space-x-0 lg:space-x-12">
            <div className="flex-1">
                <div className="mt-5">
                    <h2 className="mt-4 mb-1 text-base font-semibold leadin text-lg text-gray-900">
                        {t('settings.ticketing')}
                    </h2>
                    <div className="mt-2 flex space-between">
                        <label htmlFor="openid" className="flex-1 text-sm font-medium leading-6 text-gray-900">
                            {t('settings.enable_ticketing')}
                        </label>
                        <ToggleField name="ticketing.enabled" className="inline"/>
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="ticket_url" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.ticketing_ticket_url')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="text"
                            name="ticketing.ticket_url"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://tickets.net/showTicket?id=%d"
                        />
                        <small>{ t('settings.ticketing_ticket_url_desc') }</small>
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="reply_url" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.ticketing_reply_url')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="text"
                            name="ticketing.reply_url"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://tickets.net/showTicket?id=%d"
                        />
                        <small>{ t('settings.ticketing_reply_url_desc') }</small>
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="close_url" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.ticketing_close_url')}
                    </label>
                    <div className="mt-2">
                        <Field
                            type="text"
                            name="ticketing.close_url"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://tickets.net/showTicket?id=%d"
                        />
                        <small>{ t('settings.ticketing_close_url_desc') }</small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TicketingForm;
