import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo.png';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useMatches } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector, logout } from '../../redux';
import { Ability, ActiveTrack, Icon, QuickChangeModal, UserAvatar, Button } from '../../components';
import { Change } from '../../models';
import {Badge} from 'flowbite-react';
import type { IconType } from '../../components';
import {SearchModal} from "../../components/SearchModal";

const navigation = [
    {
        name: 'app.dashboard',
        href: '/',
        current: true,
        icon: 'home',
        ability: 'user:logout',
    },
    {
        name: 'app.projects',
        href: '/projects',
        current: false,
        icon: 'project',
        ability: 'project:read',
    },
    {
        name: 'app.goals',
        href: '/goals',
        current: false,
        icon: 'goal',
        ability: 'goal:read',
    },
    {
        name: 'app.sprints',
        href: '/sprints',
        current: false,
        icon: 'sprint',
        ability: 'sprint:read',
    },
    {
        name: 'app.quotes',
        href: '/quotes',
        current: false,
        icon: 'law',
        ability: 'quote:read',
    },
    {
        name: 'app.documentations',
        href: '/documentations',
        current: false,
        icon: 'comment',
        ability: 'documentation:read',
    },
]

function classNames(...classes : string[]) {
  return classes.filter(Boolean).join(' ')
}

function Topbar() {
    const { t, i18n }                       = useTranslation();
    const matches                           = useMatches();
    const [modalIsVisible, setModalVisible] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state : RootState) => state.auth);

    // SearchBar
    const [isSearchModalVisible, setSearchModalVisible] = useState(false);
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const element = event.target as HTMLElement;
            if (['INPUT', 'TEXTAREA'].includes(element?.tagName))
                return;

            // consider Wysiwyg editors
            if (element?.tagName === 'DIV' && element.className.includes('DraftEditor'))
                return;

            if (event.shiftKey && event.key === '/') {
                event.preventDefault();
                setSearchModalVisible(true);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Disclosure as="nav" className="bg-gray-800 print:hidden">
            {({ open }) => (
                <>
                    <div className="mx-auto px-2 md:px-6 lg:px-8">
                        <div className="relative flex h-11 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>

                            <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
                                <div className="flex-shrink-0 hidden md:flex items-center">
                                    <img className=" h-6 w-auto" src={logo} alt="Yoda" />
                                </div>
                                <SearchModal
                                    visible={isSearchModalVisible}
                                    onClose={() => setSearchModalVisible(false)}
                                />
                                <div className="hidden md:ml-6 md:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <Ability key={item.name} can={item.ability}>
                                                <Link
                                                    to={item.href}
                                                    className={classNames(
                                                        item.href === (matches[1].pathname) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                        'rounded-md px-3 py-1 text-sm font-medium flex items-center'
                                                    )}
                                                >
                                                    <Icon type={item.icon as IconType} color="white"
                                                          className="inline mr-1" size={5}/>
                                                    <span className="hidden xl:block">
                                                        {t(item.name)}
                                                    </span>
                                                </Link>
                                            </Ability>
                                        ))}
                                        <span
                                            onClick={() => setSearchModalVisible(true)}
                                            className="cursor-pointer text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-1 text-sm font-medium flex items-center"
                                        >
                                            <Icon type="search" color="white" className="inline mr-1" size={4}/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                                <Ability can="track:create">
                                    <div className="flex justify-center text-white mr-0 md:mr-3">
                                        <ActiveTrack />
                                    </div>
                                </Ability>
                                <div className="flex justify-center text-white">
                                    <Button
                                        className="block md:hidden"
                                        color="none"
                                        title=""
                                        icon="search"
                                        onClick={() => setSearchModalVisible(true)}
                                    />
                                </div>
                                <Ability can="change:create">
                                    <div className="flex justify-center text-white">
                                        <small>
                                            <Button
                                                small
                                                icon='plus'
                                                iconColor='white'
                                                iconSize={4}
                                                color='primary'
                                                title={''}
                                                textColor='white'
                                                onClick={() => setModalVisible(true)}
                                            />
                                        </small>
                                    </div>
                                </Ability>
                                {modalIsVisible && (
                                    <QuickChangeModal
                                        show={modalIsVisible}
                                        onClose={() => setModalVisible(false)}
                                        onAdded={(change: Change) => {
                                            setModalVisible(false);
                                            navigate(`/projects/${change.project}/change/${change?._id}`);
                                        }}
                                    />
                                )}
                                {/* Profile dropdown */}
                                {user && (
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="sr-only">Open user menu</span>
                                            <UserAvatar user={user} noTooltip />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y">
                                            <div className="bg-blue-9 rounded-b-md">
                                                <div className="flex justify-center text-white mb-2 px-10 pt-1 py-0 -space-x-1.5">
                                                    <Badge
                                                        className="px-2 cursor-pointer w-full"
                                                        onClick={() => i18n.changeLanguage('fr')}
                                                        color={i18n.language === 'fr' ? 'dark' : 'light'}
                                                    >
                                                        FR&nbsp;
                                                    </Badge>
                                                    <Badge
                                                        className="px-2 cursor-pointer w-full"
                                                        onClick={() => i18n.changeLanguage('en')}
                                                        color={i18n.language === 'en' ? 'dark' : 'light'}
                                                    >
                                                        EN
                                                    </Badge>
                                                    <Badge
                                                        className="px-2 cursor-pointer w-full"
                                                        onClick={() => i18n.changeLanguage('de')}
                                                        color={i18n.language === 'de' ? 'dark' : 'light'}
                                                    >
                                                        DE
                                                    </Badge>
                                                </div>
                                            </div>
                                            <Ability can="user:read">
                                                <div>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link
                                                                to={'/users'}
                                                                className={classNames(
                                                                    (active ? 'bg-gray-100' : ''),
                                                                    'w-full text-left block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                            >
                                                                <Icon type="settings" className="inline mr-1" />
                                                                { t('app.users') }
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Ability>
                                            <Ability can="setting:read">
                                                <div>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link
                                                                to={'/settings/edit/default'}
                                                                className={classNames(
                                                                    (active ? 'bg-gray-100' : ''),
                                                                    'w-full text-left block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                            >
                                                                <Icon type="setting" className="inline mr-1" />
                                                                { t('app.settings') }
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Ability>
                                            <div>
                                                <Ability can="user:logout">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link
                                                                to={`/users/edit/${user._id}`}
                                                                className={classNames(
                                                                    (active ? 'bg-gray-100' : ''),
                                                                    'w-full text-left block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                            >
                                                                <Icon type="user" className="inline mr-1" />
                                                                { t('app.user_profile') }
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                </Ability>
                                                <Ability can="track:create">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link
                                                                to={`/users/tracks`}
                                                                className={classNames(
                                                                    (active ? 'bg-gray-100' : ''),
                                                                    'w-full text-left block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                            >
                                                                <Icon type="timer" className="inline mr-1" />
                                                                { t('app.user_tracks') }
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                </Ability>
                                            </div>
                                            <Ability can="user:logout">
                                                <div>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button onClick={() => dispatch(logout())}
                                                                className={classNames(active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700')}
                                                            >
                                                                <Icon type="logout" className="inline mr-1" />
                                                                { t('app.logout') }
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Ability>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                )}
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item) => (
                                <Ability key={item.name} can={item.ability}>
                                    <Disclosure.Button
                                        as="a"
                                        href={item.href}
                                        className={classNames(
                                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block rounded-md px-3 py-2 text-base font-medium'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        <Icon type={item.icon as IconType} color="white" className="inline mr-1" size={5} />
                                        { t(item.name) }
                                    </Disclosure.Button>
                                </Ability>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

export default Topbar;
