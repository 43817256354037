import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Tabs } from 'flowbite-react';
import { useParams, useNavigate } from 'react-router-dom';
import { Ability, DocumentationList, Loader, Select, ProjectMembers, LastActivity, Reporting } from '../../../components';
import { Project } from '../../../models';
import { RootState, addFavoriteProjects, removeFavoriteProjects, useAppDispatch, useAppSelector } from '../../../redux';
import { loadProjects, loadProject, syncProject } from '../../../redux';
import {
    Alert, Icon, Button, Uploader,
    ProjectBoards, ProjectReleases, CommitsTimeline, Changelog
} from '../../../components';
import moment from 'moment';

function ProjectView() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [activeTab, setActiveTab]                 = useState(0);
    const [forceResetDisplay, setForceResetDisplay] = useState(false);

    const openFullSynchronisation = () => {
        setForceResetDisplay(!forceResetDisplay);
    }

    let { id, changeId } = useParams();

    const { user }   = useAppSelector(state => state.auth);

    const { project, projects, sync, syncProjectId, syncError } = useAppSelector((state: RootState) => state.projects);

    const handleFavoriteProject = () => {
        if (project) {
            const findProject: string = user.favoriteProjects.find((p: string) => p === project._id)
            findProject ? dispatch(removeFavoriteProjects([findProject])) : dispatch(addFavoriteProjects([project._id]));
        }
    }

    const sortedProjects: Project[] = useMemo(() => {
        if (!projects?.length)
            return [];
        return [].concat(
            projects.filter((p: Project) => user.favoriteProjects.includes(p._id))
                .sort((a: Project, b: Project) => (a.name > b.name))
                .map((p: Project) => {
                    p.isFavorite = true;
                    return p;
                })
        ).concat(
            projects.filter((p: Project) => !user.favoriteProjects.includes(p._id)).sort((a: Project, b: Project) => (a.name > b.name))
        );
    }, [projects, user.favoriteProjects]);

    useEffect(() => {
        dispatch(loadProjects());
    }, [dispatch]);

    useEffect(() => {
        if (id)
            dispatch(loadProject(id));
    }, [dispatch, id]);

    const goToProject = (projectId: string | number) => {
        navigate(`/projects/${projectId}`);
        setActiveTab(0);
    };

    const doSync = (id: string, forceRefresh: boolean) => {
        if (id)
            dispatch(syncProject(id, forceRefresh));
    };

    if (!project || project._id !== id)
        return <Loader />;

    const tabIndex = (tab: string): number => {
        switch (tab) {
            case 'release':
                return user.can('board:read') ? 1 : 0;
            case 'activity':
                return tabIndex('release') + (user.can('release:read') && project.hasConfiguredRepo() ? 1 : 0);
            case 'commits':
                return tabIndex('activity') + (user.can('log:read') ? 1 : 0);
            case 'changelog':
                return tabIndex('commits') + (user.can('commit:read') && project.hasConfiguredRepo() ? 1 : 0);
            case 'documentation':
                return tabIndex('changelog') + (user.can('release:read') && project.hasConfiguredRepo() ? 1 : 0);
            case 'attachments':
                return tabIndex('documentation') + (user.can('documentation:read') && project.documentations.length ? 1 : 0);
            case 'reporting':
                return tabIndex('attachments') + (user.can('project:reporting:read') ? 1 : 0);
        }

        return 0;
    }

    const isActiveTab = (tab: string): boolean => {
        return activeTab === tabIndex(tab);
    };

    const theme = {
        "base": "flex flex-col gap-2",
        "tablist": {
            "base": "grid grid-cols-2 sm:grid-cols-4 md:flex text-center",
            "styles": {
                "underline": "flex-wrap -mb-px border-b border-gray-200 dark:border-gray-700",
            },
            "tabitem": {
                "base": "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 !focus:ring-0 focus:outline-none",
                "styles": {
                    "underline": {
                        "base": "rounded-t-lg",
                        "active": {
                            "on": "text-cyan-600 rounded-t-lg border-b-2 border-cyan-600 active dark:text-cyan-500 dark:border-cyan-500",
                            "off": "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
                        }
                    },
                },
                "icon": "mr-2 h-5 w-5"
            }
        },
        "tabitemcontainer": {
            "base": "",
            "styles": {
                "underline": "",
            }
        },
        "tabpanel": "py-3"
    };

    document.title = `Yoda - ${t('projects.project')} ${project.name}`;

    return (
        <Ability can="project:read" redirect="/">
            <div className="ProjectView">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8 pb-2 lg:pb-0">
                    <div className="mx-auto lg:flex lg:itemProjects-center lg:justify-between items-center">
                        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0">
                            <h2 className="flex items-center text-3xl font-bold tracking-tight text-gray-900">
                                <Avatar img={project.getAvatarUrl(45)} className="inline" size="md" />
                                <Select
                                    onChange={goToProject}
                                    placeholder={project?.name}
                                    options={sortedProjects.map((project: Project) => ({
                                        value: project._id,
                                        label: project.name,
                                        className: 'text-xl font-bold',
                                        leftContent: <Avatar img={project.getAvatarUrl()} className="inline ml-2" />,
                                        rightContent: project.isFavorite ? <Icon type="fullStar" color="yellow-400" className="mr-2"/> : null
                                    }))}
                                    inline
                                    className=""
                                    optionsWidth={'w-[20rem]'}
                                    optionsHeight={'max-h-96'}
                                    selectedClassName="text-2xl font-bold text-gray-900"
                                    optionsClassName="py-4 pl-14"
                                />
                            </h2>
                            <Ability can="project:members:read">
                                <ProjectMembers />
                            </Ability>
                        </div>
                            {/* TODO : mettre un button avec "icone" + text comme sur les bouttons */}
                        <div className="grid grid-cols-2 gap-2 md:flex md:flex-row md:space-x-2 lg:ml-4 mt-2 lg:mt-0 w-100 overflow-x-auto">
                            <span className="flex justify-end">
                                <Button
                                    onClick={handleFavoriteProject}
                                    title={t('projects.favorite')}
                                    type='button'
                                    color='warning'
                                    className="w-full"
                                    iconColor='white-500'
                                    icon={user.favoriteProjects.find((p: string) => p === project._id) ? "fullStar" : "emptyStar"}
                                />
                            </span>
                            {project?.hasConfiguredRepo() && user?.can('project:sync') && (
                            <span className="flex flex-row relative sm:mr-3">
                                <Button
                                    onClick={() => doSync(project._id, /*forceRefresh*/false)}
                                    title={t('projects.sync')}
                                    icon="sync"
                                    loading={(syncProjectId === project._id) && (sync === 'pending')}
                                    color="primary"
                                    className={`w-full rounded-e-none ${forceResetDisplay ? 'hidden' : 'flex'}`}
                                />
                                <Button
                                    onClick={() => doSync(project._id, /*forceRefresh*/true)}
                                    title={t('projects.sync_full')}
                                    icon="sync"
                                    loading={(syncProjectId === project._id) && (sync === 'pending')}
                                    color="default"
                                    className={`w-full rounded-e-none ${forceResetDisplay ? 'flex' : 'hidden'}`}
                                />
                                <Button
                                    className={`h-9 rounded-s-none border-l`}
                                    color='primary'
                                    title=''
                                    icon={`${forceResetDisplay ? 'close' : 'dots'}`}
                                    onClick={() => openFullSynchronisation()}
                                />
                            </span>
                            )}
                            <Ability can="project:edit">
                                <span className="flex justify-end">
                                    <Button
                                        to={`/projects/edit/${project?._id}/`}
                                        title={t('common.edit')}
                                        icon="edit"
                                        color="secondary"
                                        className="w-full"
                                    />
                                </span>
                            </Ability>
                            <span>
                                <Button title={t('common.back')} className="w-full" color="navigateBack" to="/projects" icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                {(syncProjectId === project?._id) && (sync !== 'idle') && (
                <div className="flex-1">
                    {sync === 'pending' && (
                        <Alert title={t('projects.syncing')} color="info" icon="loading" />
                    )}
                    {sync === 'succeeded' && (
                        <Alert title={t('projects.sync_succeeded')} color="success" icon="check" />
                    )}
                    {syncError && (
                        <Alert title={t('projects.sync_failed') + ' : ' + syncError} color="failure" icon="warning" />
                    )}
                </div>
                )}
                <main className="mx-3 mt-1">
                    {project && (project._id === id) && (
                        <Tabs style="underline" onActiveTabChange={setActiveTab} theme={theme} >
                            {user?.can('board:read') && (
                            <Tabs.Item icon={() => <Icon type="board" className="mr-1.5 outline-none" color="gray-800"/>} title={t('projects.boards')}>
                                <div className="-mt-3 -mb-4">
                                    {activeTab === 0 && (
                                        <ProjectBoards
                                            project={project}
                                            changeId={changeId}
                                        />
                                    )}
                                </div>
                            </Tabs.Item>
                            )}
                            {user?.can('release:read') && project.hasConfiguredRepo() ? (
                            <Tabs.Item icon={() => <Icon type="release" className="mr-1.5 outline-none" />} title={t('projects.releases')}>
                                <div className="-mt-3 flex-1 shrink">
                                    {isActiveTab('release') && (
                                        <ProjectReleases project={project} />
                                    )}
                                </div>
                            </Tabs.Item>
                            ) : ''}
                            {user?.can('log:read') && (
                            <Tabs.Item icon={() => <Icon type="activity" className="mr-1.5 outline-none" />} title={t('projects.activity')}>
                                <div className="flex-1 shrink">
                                    {isActiveTab('activity') && (
                                        <LastActivity title={t('projects.activity') || ''} project={project?._id} limit={100} />
                                    )}
                                </div>
                            </Tabs.Item>
                            )}
                            {user?.can('commit:read') && project.hasConfiguredRepo() ? (
                            <Tabs.Item icon={() => <Icon type="commit" className="mr-1.5 outline-none" />} title={t('projects.commits')}>
                                <div className="flex-1 shrink">
                                    {isActiveTab('commits') && (
                                        <CommitsTimeline project={project} />
                                    )}
                                </div>
                            </Tabs.Item>
                            ) : ''}
                            {user?.can('release:read') && project.hasConfiguredRepo() ? (
                            <Tabs.Item icon={() => <Icon type="changelog" className="mr-1.5 outline-none" />} title={t('projects.changelog')}>
                                <div className="flex-1 shrink">
                                    {isActiveTab('changelog') && (
                                        <Changelog project={project} />
                                    )}
                                </div>
                            </Tabs.Item>
                            ) : ''}
                            {user?.can('documentation:read') && project.documentations.length && (
                            <Tabs.Item icon={() => <Icon type="comment" className="mr-1.5 outline-none" />} title={t('projects.documentation')}>
                                <div className="flex-1 shrink">
                                    {isActiveTab('documentation') && (
                                        <DocumentationList projectId={project._id}/>
                                    )}
                                </div>
                            </Tabs.Item>
                            )}
                            {user?.can('attachment:read') && (
                            <Tabs.Item
                                icon={() => <Icon type="file" className="mr-1.5 outline-none" />}
                                title={t('projects.attachments') + (project?.nbAttachments > 0 ? ' (' + project.nbAttachments +')' : '')}
                            >
                                <div className="flex-1 shrink">
                                    {isActiveTab('attachments') && (
                                        <Uploader projectId={project?._id} showChangeSlug/>
                                    )}
                                </div>
                            </Tabs.Item>
                            )}
                            {user?.can('project:reporting:read') && (
                            <Tabs.Item
                                icon={() => <Icon type="report" className="mr-1.5 outline-none" />}
                                title={t('projects.reporting')}
                            >
                                <div className="flex-1 shrink">
                                    {isActiveTab('reporting') && (
                                        <Reporting
                                            project={project}
                                            client={project.clients?.length === 1 ? project.clients[0] : undefined}
                                            from={moment().startOf('month')}
                                            to={moment().endOf('day')}
                                            tabs={['stats', 'report', 'tracks']}
                                        />
                                    )}
                                </div>
                            </Tabs.Item>
                            )}
                        </Tabs>
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default ProjectView;
